const blogListStyles = {
  posts: {
    backgroundColor: "coverBackground",
    pb: [3, 4, 5],
  },
  container: {
    variant: "variants.container",
  },
  heading: {
    fontWeight: 900,
    fontSize: 5,
    mb: 0,
  },
  paragraph: {
    mt: 0,
    mb: 0,
    color: "rgba(0,0,0,0.8)",
  },
}
export default blogListStyles
