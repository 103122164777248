import React from "react"
import { Link } from "gatsby"

import "../assets/scss/post-card.scss"

const PostCard = ({ data }) => {
  const post = data
  return (
    <div className="post-card">
      <Link to={post.prefix + post.slug + "/"} className="card-click"></Link>
      <div className="post-info">
        <h3>
          <Link to={post.prefix + post.slug} className="card-title">
            {post.title}
          </Link>
        </h3>
        <time dateTime={post.date}>{post.date}</time>
      </div>
    </div>
  )
}

export default PostCard
