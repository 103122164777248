/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/layout"
import PostCard from "../components/post-card"
import SEO from "../components/seo"
import blogListStyles from "../templates/blog-list.styles"

export const blogListQuery = graphql`
  query blogListQuery {
    allStrapiPosts(sort: { order: DESC, fields: PublishDate }) {
      edges {
        node {
          id
          meta_description
          PublishDate(formatString: "MMMM DD, YYYY")
          Title
          Slug
          prefix
        }
      }
    }
  }
`

class BlogIndex extends React.Component {
  render() {
    const { data } = this.props
    const { currentPage, numPages } = this.props.pageContext

    const posts = data.allStrapiPosts.edges
      .filter(edge => !!edge.node.PublishDate)
      .map(edge => {
        const props = {
          prefix: edge.node.prefix,
          slug: edge.node.Slug,
          date: edge.node.PublishDate,
          title: edge.node.Title,
        }
        return <PostCard key={edge.node.id} data={props} />
      })

    return (
      <Layout>
        <SEO
          title={"Blog • Stackrole"}
          description={
            "Stackrole base blog page " + currentPage + " of " + numPages
          }
        />
        <div className="blog-posts" sx={blogListStyles.posts}>
          <div sx={blogListStyles.container}>
            <h1 sx={blogListStyles.heading}>Blog</h1>
            <p sx={blogListStyles.paragraph}>
              We write How-to's and article related to JAMStack, Gatsby, Netlify
              CMS, Netlify and Web in general.{" "}
            </p>
            <div className="post-grids">{posts}</div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default BlogIndex
